@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');


.swiper-container-free-mode>.swiper-wrapper {
    transition-timing-function: linear;
}

.loaderWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FCEE21;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.form-control {
    width: 300px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
    outline: none !important;
}

.btn {

    width: 300px;
    margin-inline: auto;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    border: none;
    background-color: #007bff;
    color: #fff;
    margin-bottom: 1rem;

}